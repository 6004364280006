<template>
    <div class="w-screen p-6">
        <div class="md-layout md-gutter">
           <div class="md-layout bg-white" >    
            <Content textColor="black" labelClass="text-gray-900" label="Name" class="my-3 text-uppercase w-full"  :text="content.name" /> 
            <Content textColor="black" labelClass="text-gray-900"  label="Email" class="my-3 text-uppercase w-full"  :text="content.email" /> 
            <Content textColor="black" labelClass="text-gray-900"  label="Contact Number" class="my-3 text-uppercase w-full"  :text="content.user_profile?content.user_profile.contact_number:''" /> 
            <Content textColor="black" labelClass="text-gray-900"  label="Address" class="my-3 text-uppercase w-full"  :text="content.user_profile?content.user_profile.address:''" /> 
            <Content textColor="black" labelClass="text-gray-900"  label="Country" class="my-3 text-uppercase w-full"  :text="content.user_profile && content.user_profile.country_detail?content.user_profile.country_detail.name:''" /> 
            <Content textColor="black" labelClass="text-gray-900"  label="State" class="my-3 text-uppercase w-full"  :text="content.user_profile && content.user_profile.postal_code_detail?content.user_profile.postal_code_detail.state:''" /> 
            <Content textColor="black" labelClass="text-gray-900"  label="City" class="my-3 text-uppercase w-full"  :text="content.user_profile && content.user_profile.postal_code_detail?content.user_profile.postal_code_detail.city:''" /> 
           
        </div>
    </div>
</div>
</template>

<script>

import Content from "@/components/atom/Content";
import VueClipboard from 'vue-clipboard2';
import Vue from 'vue';

Vue.use(VueClipboard)

export default {
  components: {
    Content,
  },
   props: {
      content: {
          type: Object
      }
  },
  data: function () {
    return {
      active: false,
      component: 'ShowStudentDetails',
    }
  },
  methods: {

  },
  created(){
  }

}
</script>
<style scoped>
</style>